// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aeRXHaAMP_OfTzyerBFM{display:flex;justify-content:center;margin-top:60px;padding:0 20px}.E7_YJaHp61yRWPovylUw{display:flex;flex-direction:column;gap:60px 0;width:var(--default-layout-width)}@media(max-width: 1024px){.E7_YJaHp61yRWPovylUw{width:100%;flex-direction:column}}`, "",{"version":3,"sources":["webpack://./src/pages/Home/NewsLibrary/NewsLibrary.module.scss"],"names":[],"mappings":"AAGA,sBACI,YAAA,CACA,sBAAA,CACA,eAAA,CACA,cAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,UAAA,CACA,iCAAA,CAAA,0BAJJ,sBAOQ,UAAA,CACA,qBAAA,CAAA","sourcesContent":["@import '../../../styles/mixins';\n@import '../../../styles/variables';\n\n.wrapper {\n    display: flex;\n    justify-content: center;\n    margin-top: 60px;\n    padding: 0 20px;\n}\n\n.inner {\n    display: flex;\n    flex-direction: column;\n    gap: 60px 0;\n    width: var(--default-layout-width);\n\n    @include respond-to(lg) {\n        width: 100%;\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `aeRXHaAMP_OfTzyerBFM`,
	"inner": `E7_YJaHp61yRWPovylUw`
};
export default ___CSS_LOADER_EXPORT___;
